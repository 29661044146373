.cartIconContainer {
	display: flex;
	align-items: center;
	padding-right: 1em;
	// margin-right: .5em;
	cursor: pointer;
	span {
		// @include mmq(display, (none,none,block));
	}

	.priceInfoContainer {
		margin-right: 1em;
		text-align: right;
		span {
			display: block;
		}
		.priceInfoItems {
			white-space: nowrap;
			font-size: 1em;

			span {
				display: inline-block;
			}

			.emptyCartInfo {
				// @include mmq(display, (none, none, inline-block));
			}
		}

		.priceInfoShipping {
			transition: color 0.2s linear;
			font-size: 0.7em;

			@media (max-width: 480px) {
				display: none;
			}
		}
	}

	border-right: 1px dotted currentColor;
}
