body {
	* {
		text-rendering: optimizeLegibility;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
	}

	background-color: rgba(255, 255, 255, 1);
}

:global {
	@import './normalize.css';

	blockquote,
	p,
	span,
	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		transition: font-size 0.4s ease-in-out, padding 0.4s ease-in-out, border-color 0.4s ease-in-out;
	}

	font-family: 'Amasis W01', 'Garamond', serif;

	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		font-family: 'Proxima Nova W01', 'Arial', 'Helvetica', sans-serif;
		margin-top: 0;
		margin-bottom: 0;
	}

	a {
		font-size: inherit;
		font-family: inherit;
		color: inherit;
		text-decoration: none;
	}

	font-weight: 100;

	button {
		border: none;
		background-color: transparent;
	}

	a:focus,
	button:focus,
	input:focus {
		outline: none;
	}
}
