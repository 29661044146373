@charset "UTF-8";
.markdown {
  column-gap: 2em;
  column-rule: 1px dotted rgba(0, 0, 0, 0.1);
  font-size: .9rem; }
  .markdown a {
    text-decoration: underline; }
  .markdown code {
    white-space: pre-wrap; }
  @media print {
    .markdown {
      column-count: 1; } }
  .markdown strong {
    font-weight: 500; }
  .markdown ul {
    list-style-type: disc;
    margin-left: 1.5em; }
  .markdown * {
    hypens: auto;
    line-height: 130%; }
  .markdown p {
    font-size: 1em;
    margin-bottom: 1em; }
  .markdown em {
    text-decoration: underline; }
  .markdown pre {
    overflow: hidden;
    padding: 2em;
    font-size: .8em;
    background-color: rgba(0, 0, 0, 0.03);
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 0; }
  .markdown td {
    padding-right: 2em; }
  .markdown h1, .markdown h2, .markdown h3, .markdown h4 {
    margin-top: .75em;
    margin-bottom: .5em; }
    .markdown h1:first-child, .markdown h2:first-child, .markdown h3:first-child, .markdown h4:first-child {
      margin-top: 0; }
  .markdown h1 {
    font-size: 1.5em;
    font-weight: 500;
    text-transform: uppercase; }
  .markdown h2 {
    font-size: 1.2em;
    margin-top: 1.5em; }
  .markdown h3 {
    font-size: 1.1em; }
  .markdown h2, .markdown h3, .markdown h4 {
    font-weight: 400; }
  .markdown code {
    color: black;
    background-color: transparent; }
  .markdown h1[id*="allgemeine-gesch"] {
    counter-reset: h2counter h3counter; }
    .markdown h1[id*="allgemeine-gesch"] ~ h2 {
      counter-reset: h3counter; }
    .markdown h1[id*="allgemeine-gesch"] ~ h2:before {
      content: counter(h2counter) ". ";
      counter-increment: h2counter; }
    .markdown h1[id*="allgemeine-gesch"] ~ h3:before[id="impressum"] {
      content: counter(h2counter) "." counter(h3counter) ". ";
      counter-increment: h3counter;
      counter-reset: h4counter; }
